import PropTypes from 'prop-types';
// material
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import Image from './Image';

// ----------------------------------------------------------------------

Logo.propTypes = {
  mini: PropTypes.bool,
  logo: PropTypes.string,
  logo1: PropTypes.string,
  sx: PropTypes.object
};

export default function Logo({ sx, mini = true, logo = '/static/brand/logo.png', logo1 = '/static/brand/logo1.png' }) {
  const theme = useTheme();

  return (
    <Box sx={{ width: 70, height: 70, ...sx }}>
      <Image visibleByDefault disabledEffect alt="LOGO" src={mini ? logo1 : logo} />
    </Box>
  );
}
